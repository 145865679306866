import React from 'react';
import { Flex, Image, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

const ExperienceBlockExt = ({
    company,
    position,
    date,
    accomplishments,
    position2,
    date2,
    accomplishments2,
    imgSrc,
    imgAlt,
    mt,
    mb,
  }) => {
    return (
      <Flex
        direction={{ base: 'column', sm: 'column', lg: 'row' }}
        align="center"
        mt={mt}
        mb={mb}
        py="4"
        px="8"
        borderRadius={'lg'}
        bgGradient={'linear(to-r, #3c1053, #ad5389)'}
        boxShadow={'2xl'}
      >
        <Image
          src={imgSrc}
          alt={imgAlt}
          boxSize="150px"
          mr="8"
          borderRadius={'md'}
        />
        <Flex direction="column">
          <Text fontSize="3xl" textDecorationLine={'underline'}>
            {company}
          </Text>
          <Text fontSize="2xl" fontWeight={'semibold'}>
            {position}
          </Text>
          <Text fontStyle={'italic'}>{date}</Text>
          <List>
            {accomplishments &&
              accomplishments.map((item, index) => (
                <ListItem key={`${index}_${item}`}>
                  <ListIcon as={ChevronRightIcon} />
                  {item}
                </ListItem>
              ))}
          </List>
          <Text fontSize="2xl" fontWeight={'semibold'}>
            {position2}
          </Text>
          <Text fontStyle={'italic'}>{date2}</Text>
          <List>
            {accomplishments2 &&
              accomplishments2.map((item, index) => (
                <ListItem key={`${index}_${item}`}>
                  <ListIcon as={ChevronRightIcon} />
                  {item}
                </ListItem>
              ))}
          </List>
        </Flex>
      </Flex>
    );
  };

  export default ExperienceBlockExt;
