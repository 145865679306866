import React from 'react';
import { Flex } from '@chakra-ui/react';
import NavLink from './NavLink';

const NavBar = () => {
  return (
    <Flex
      display={{ base: 'none', sm: 'none', lg: 'flex' }}
      align="center"
      color="white"
      border="1px solid grey"
      maxW="100vw"
      bgColor="rgba(1, 4, 9, .35)"
      backdropFilter={'blur(5px)'}
      borderRadius={'100px'}
      position={'sticky'}
      top="0"
      zIndex={10}
    >
      <NavLink linkName={'Skills'} linkDst={'skills'} />
      <NavLink linkName={'Tools'} linkDst={'tools'} />
      <NavLink linkName={'Experience'} linkDst={'experience'} />
      <NavLink linkName={'Education'} linkDst={'education'} />
      <NavLink linkName={'Projects'} linkDst={'projects'} />
    </Flex>
  );
};

export default NavBar;
