import { Flex, Image, Text } from '@chakra-ui/react';

const EducationBlockExt = ({
  imgSrc,
  imgAlt,
  schoolName,
  degreeName,
  degreeYear,
  degreeName2,
  degreeYear2,
  degreeName3,
  degreeYear3,
  degreeName4,
  degreeYear4,
  degreeName5,
  degreeYear5,
  mb,
  mt,
}) => {
  return (
    <Flex
      direction={{ base: 'column', sm: 'column', lg: 'row' }}
      align={'center'}
      mb={mb}
      mt={mt}
      bgGradient="linear(to-r, #ad5389, #3c1053)"
      p="4"
      w="100%"
      borderRadius={'lg'}
      boxShadow={'2xl'}
    >
      <Image
        src={imgSrc}
        boxSize={'150px'}
        alt={imgAlt}
        mr="12"
        borderRadius={'md'}
        objectFit={'scale-down'}
      />
      <Flex direction={'column'}>
        <Text fontSize={'2xl'} textDecor={'underline'}>
          {schoolName}
        </Text>
        <Text fontSize={'xl'} fontStyle={'italic'}>
          {degreeName}
        </Text>
        <Text fontSize={'lg'} fontWeight={'thin'}>
          {degreeYear}
        </Text>
        <Text fontSize={'xl'} fontStyle={'italic'}>
          {degreeName2}
        </Text>
        <Text fontSize={'lg'} fontWeight={'thin'}>
          {degreeYear2}
        </Text>
        <Text fontSize={'xl'} fontStyle={'italic'}>
          {degreeName3}
        </Text>
        <Text fontSize={'lg'} fontWeight={'thin'}>
          {degreeYear3}
        </Text>
        <Text fontSize={'xl'} fontStyle={'italic'}>
          {degreeName4}
        </Text>
        <Text fontSize={'lg'} fontWeight={'thin'}>
          {degreeYear4}
        </Text>
        <Text fontSize={'xl'} fontStyle={'italic'}>
          {degreeName5}
        </Text>
        <Text fontSize={'lg'} fontWeight={'thin'}>
          {degreeYear5}
        </Text>
      </Flex>
    </Flex>
  );
};

export default EducationBlockExt;