import React from 'react';
import { Flex, Heading, Text } from '@chakra-ui/react';
import ExperienceBlock from './ExperienceBlock';
import ExperienceBlockExt from './ExperienceBlockExt';
//import csuf_logo from '../../images/experience/1200px-California_State_University,_Fullerton_seal.svg.png';
import fp_logo from '../../images/experience/fp.png';
import mudd_logo from '../../images/experience/mudd.png';
import gos_logo from '../../images/experience/gos.png';

const Experience = () => {
  return (
    <Flex
      direction={{ base: 'column', sm: 'column', lg: 'row' }}
      py={{ base: '4', sm: '4', lg: '16' }}
      mx={{ base: '0', sm: '0', lg: '4' }}
      align="center"
      w="100%"
      color="white"
      id="experience"
    >
      <Flex
        mr={{ base: '0', sm: '0', lg: '12' }}
        textAlign={{ base: 'center', sm: 'center', lg: 'start' }}
        w={{ base: '100%', sm: '100%', lg: '50%' }}
        mb={{ base: '4', sm: '4', lg: '0' }}
        id="experience-left"
        direction="column"
        justify="center"
      >
        <Heading mb={{ base: '2', sm: '2', lg: '4' }}>
          Professional Experience
        </Heading>
        <Text fontStyle={'italic'}>
          More than seven years of professional IT and System Administration experience working with a wide range of technologies.
        </Text>
      </Flex>
      <Flex id="experience-right" direction="column">
        <ExperienceBlockExt
          company={'Fisher Phillips'}
          position={'System Administrator'}
          date="Aug 2020 - Present"
          imgSrc={fp_logo}
          imgAlt={'Fisher Phillips Logo'}
          accomplishments={[
            `Prioritize and assist in maintaining a 99.992% system and service uptime`,
            `Remain on‑call as the West Coast Datacenter SRE`,
            `Oversee the maintainance and integrity of backups and failover capability as the Backup and Disaster Recovery Engineer`,
            `Utilize Jenkins to automate and orchestrate backup and failover continuity checks`,
            `Implemented and operate the VXRail and vCenter/vSphere hyperconverged infrastructure across multiple datacenters`,
            `Leverage the CrowdStrike Falcon platform for asset and user security management`,
            `Collaborate with and supported the Security team in monitoring and remediating security threats across all endpoints`,
            `Manage and Monitor the O365 environment and external vendor integrations including Mimecast and Okta`,
            `Improve the nation-wide availability of all internal services via Zscaler, SilverPeak SDWAN, and FortiGate NGFW/VPN`
          ]}
          position2={'Technical Support Technician'}
          date2="Sep 2019 - Aug 2020"
          accomplishments2={[
            `Served and supported all organization memebers with IT related assistance`,
            `Coordinated and Led training to improve the technical knowledge of both immediate team members as well as general organization members`,
            `Redesigned the assest imaging and setup processes using PowerShell and VBScript to introduce automation`,
            `Simplified general technical documentation to provide easy to read instructions to both team members and end users`,
            `Engineered a content delivery mechanism with SCCM, and VisualBasic to deploy a remote assistance launcher on the fly to all organization members`,
          ]}
          mb={{ base: '6', sm: '6', lg: '12' }}
        />
        {/*<ExperienceBlock
          company={'Fisher Phillips'}
          position={'Technical Support Technician'}
          date="Sep 2019 - Aug 2020"
          imgSrc={fp_logo}
          imgAlt={'Fisher Phillips Logo'}
          accomplishments={[
            `Served and supported all organization memebers with IT related assistance`,
            `Coordinated and Led training to improve the technical knowledge of team members and the broader organization members`,
            `Redesigned the assest imaging and setup processes using PowerShell to introduce automation`,
            `Simplified general technical documentation to provide`,
            `Engineered a content delivery mechanism with SCCM, and VisualBasic to deploy a remote assistance launcher on the fly all organization members`,
          ]}
          mb={{ base: '6', sm: '6', lg: '12' }}
        />*/}
        <ExperienceBlock
          company={'Mudd Industries Inc.'}
          position={'Technical Consultant'}
          date="Oct 2017 - Aug 2020"
          imgSrc={mudd_logo}
          imgAlt={'Mudd Industries Inc. Logo'}
          accomplishments={[
            `Overhauled network and system infrastructure with new technologies to improve and increase overall speed, reliability, and uptime`,
            `Managed Windows and Linux Server Environment and perform software and hardware maintenance`,
            `Engineered a resiliant backup envionrment using S3 CLI and Bash with the ability to do multi‑site offloading`,
          ]}
          mb={{ base: '6', sm: '6', lg: '12' }}
        />
        <ExperienceBlock
          company={'Geeks on Site'}
          position={'Field Service Technician'}
          date="Feb 2019 ‑ Jan 2020"
          imgSrc={gos_logo}
          imgAlt={'Geeks on Site Logo'}
          accomplishments={[
            `Performed repairs or installations for laptops, desktops and printers`,
            `Purchased new eqipment or replacement parts for both consumer and enterprise clients`,
            `Educated clients on new hardware, software, and safe computer practices`,
          ]}
          mb={{ base: '6', sm: '6', lg: '12' }}
        />
      </Flex>
    </Flex>
  );
};

export default Experience;
