import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
//import csuf_logo from '../../images/education/csuf.png';
import csuf_logo from '../../images/education/csuf.svg';
import saddleback_logo from '../../images/education/1200px-Saddleback_College_logo.svg.png';
import EducationBlock from './EducationBlock';
import EducationBlockExt from './EducationBlockExt';
import Coursework from './Coursework';

const Education = () => {
  return (
    <Flex
      direction={{ base: 'column', sm: 'column', lg: 'row' }}
      py={{ base: '4', sm: '4', lg: '16' }}
      w="100%"
      color="white"
      align="center"
      id="education"
    >
      <Flex
        w={{ base: '100%', sm: '100%', lg: '50%' }}
        mr={{ base: '0', sm: '0', lg: '12' }}
        direction="column"
      >
        <Heading as="h1" mb="4">
          Education
        </Heading>
        <EducationBlock
          imgSrc={csuf_logo}
          imgAlt={'Cal State Fullerton Logo'}
          schoolName={'California State University, Fullerton'}
          degreeName={'Bachelor of Science - BS, Computer Science'}
          degreeYear={'2023'}
          mb={{ base: '4', sm: '4', lg: '8' }}
        />
        <EducationBlockExt
          imgSrc={saddleback_logo}
          imgAlt={'Saddleback Community College Logo'}
          schoolName={'Saddleback College'}
          degreeName={'Associate of Science - AS, Computer Science'}
          //degreeYear={'2021'}
          degreeName2={'Associate of Science - AS, Cyber Defense'}
          //degreeYear2={'2022'}
          degreeName3={'Associate of Science - AS, Network Administrator'}
          //degreeYear3={'2021'}
          degreeName5={'Associate of Science - AS, Webmaster'}
          //degreeYear5={'2021'}
          degreeName4={'Associate of Science - AS, Computer Maintenance Technology'}
          //degreeYear4={'2021'}
        />
      </Flex>
      <Coursework />
    </Flex>
  );
};

export default Education;
