import React from 'react';
import { Flex, Heading, Text, SimpleGrid } from '@chakra-ui/react';
import SkillTile from './SkillTile.js';

import bash from '../../images/tools/bash.png';
import python from '../../images/tools/64px-Python-logo-notext.svg.png';
import cpp from '../../images/tools/128px-ISO_C++_Logo.svg.png';
import powershell from '../../images/tools/powershell.png';
import vb from '../../images/tools/vb.png';

import jenkins from '../../images/tools/jenkins.png';
import gitlab from '../../images/tools/gitlab.png';
import travis from '../../images/tools/travis.png';
import chef from '../../images/tools/chef.png';
import puppet from '../../images/tools/puppet.png';

import veeam from '../../images/tools/veeam.png';
import jira from '../../images/tools/jira.png';
import vmware from '../../images/tools/vmware.png';
import vxrail from '../../images/tools/vxrail.png';
import sccm from '../../images/tools/sccm.png';

import kubes from '../../images/tools/kubes.png';
import ssms from '../../images/tools/ssms.png';
import falcon from '../../images/tools/falcon.png';
import redcloak from '../../images/tools/redcloak.png';
import varonis from '../../images/tools/varonis.png';

import ec2 from '../../images/tools/Arch_Amazon-EC2_64.svg';
import codebuild from '../../images/tools/Arch_AWS-CodeBuild_64.svg';
import lambda from '../../images/tools/Arch_AWS-Lambda_64.svg';
import cloudfront from '../../images/tools/Arch_Amazon-CloudFront_64.svg';
import cloudwatch from '../../images/tools/Arch_Amazon-CloudWatch_64.svg';

const Tools = () => {
  return (
    <Flex
      direction={{ base: 'column', sm: 'column', lg: 'row' }}
      py={{ base: '4', sm: '4', lg: '16' }}
      align="center"
      w="100%"
      color="white"
      id="tools"
    >
      <Flex
        mr={{ base: '0', sm: '0', lg: '12' }}
        maxW={{ base: '100%', sm: '100%', lg: '40%' }}
        textAlign={{ base: 'center', sm: 'center', lg: 'start' }}
        direction="column"
      >
        <Heading id="about" mb={'2'}>
          Tools of the Trade
        </Heading>
        <Text mb={{ base: 4, sm: 4, lg: 0 }} fontStyle={'italic'}>
          Well versed in both On-Premise and Cloud operations.
          Specialized in the following tools, languages, and technologies with a wide breadth of knowledge in many others.
        </Text>
      </Flex>
      <SimpleGrid spacing="15px" columns={{ base: 4, sm: 4, lg: 5 }}>
        <SkillTile imgSrc={bash} name="BASH" />
        <SkillTile imgSrc={python} name="Python" />
        <SkillTile imgSrc={cpp} name="C++" />
        <SkillTile imgSrc={powershell} name="PowerShell" />
        <SkillTile imgSrc={vb} name="VisualBasic" />

        <SkillTile imgSrc={jenkins} name="Jenkins" />
        <SkillTile imgSrc={gitlab} name="GitLab" />
        <SkillTile imgSrc={travis} name="Travis CI" />
        <SkillTile imgSrc={chef} name="Chef" />
        <SkillTile imgSrc={puppet} name="Puppet" />

        <SkillTile imgSrc={veeam} name="Veeam" />
        <SkillTile imgSrc={jira} name="Jira" />
        <SkillTile imgSrc={vmware} name="VMWare" />
        <SkillTile imgSrc={vxrail} name="VXRail" />
        <SkillTile imgSrc={sccm} name="SCCM" />

        <SkillTile imgSrc={kubes} name="Kubernetes" />
        <SkillTile imgSrc={ssms} name="SSMS" />
        <SkillTile imgSrc={falcon} name="CrowdStrike" />
        <SkillTile imgSrc={redcloak} name="RedCloak" />
        <SkillTile imgSrc={varonis} name="Varonis" />

        <SkillTile imgSrc={ec2} name="AWS EC2" />
        <SkillTile imgSrc={codebuild} name="CodeBuild" />
        <SkillTile imgSrc={lambda} name="Lambda" />
        <SkillTile imgSrc={cloudfront} name="CloudFront" />
        <SkillTile imgSrc={cloudwatch} name="CloudWatch" />
      </SimpleGrid>
    </Flex>
  );
};

export default Tools;