import { Flex, Image, Text } from '@chakra-ui/react';

const EducationBlock = ({
  imgSrc,
  imgAlt,
  schoolName,
  degreeName,
  degreeYear,
  mb,
  mt,
}) => {
  return (
    <Flex
      direction={{ base: 'column', sm: 'column', lg: 'row' }}
      align={'center'}
      mb={mb}
      mt={mt}
      bgGradient="linear(to-r, #ad5389, #3c1053)"
      p="4"
      w="100%"
      borderRadius={'lg'}
      boxShadow={'2xl'}
    >
      <Image
        src={imgSrc}
        boxSize={'150px'}
        alt={imgAlt}
        mr="12"
        borderRadius={'md'}
        objectFit={'scale-down'}
      />
      <Flex direction={'column'}>
        <Text fontSize={'2xl'} textDecor={'underline'}>
          {schoolName}
        </Text>
        <Text fontSize={'xl'} fontStyle={'italic'}>
          {degreeName}
        </Text>
        <Text fontSize={'lg'} fontWeight={'thin'}>
          {degreeYear}
        </Text>
      </Flex>
    </Flex>
  );
};

export default EducationBlock;